$img-url: '//img.ythshop.com/';
/*背景色灰色*/
$bg-color: #f1f3f6;
/*默认背景色*/
$default-bg-color: #fff;
/*默认字体大小*/
$default-font-size: 14px;
/*用于table td 字体 placeholder字体大小*/
$default-mini-font-size: 12px;
/*树悬浮颜色*/
$tree-hover-color: #F3F8FF;

$primary-color: #ff8933;
$default-color: #ECEFF2;
$border-color: #ecedee;
$font-color: #333;
$content-color: #666;
$minor-color: #999;
$handle-color: #679dff;
$header-top-bgcolor: #41464B;
$header-top-color: #c7c7c7;
$complate-icon-color: #8cc856;

$tip-font-color: #d6a962;
$tip-highlight-color: #f45d5d;
$tip-border-color: #fbd597;
$tip-bg-color: #fbf3ee;

$step-bg-color: #dedede;

$app-classify-color: #484c59;

$tab-default-color: #454545;






























































































































@import '@/common/css/variable.scss';
.wrap {
  height: 100%;
  min-height: 100vh;
  min-width: 100%;
}
.container-fluid {
  min-width: 100%;
  min-height: calc(100vh - 64px);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    width: 72px;
    background: #32394D;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
  .main {
    margin-left: 218px;
    background: #f1f3f6;
    overflow: hidden;
    min-height: calc(100vh - 64px);
    height: 100%;
    padding-bottom: 16px;
    box-sizing: border-box;
    .main-content-wrap {
      padding-left: 16px;
      padding-top: 16px;
      padding-right: 16px;
      >div {
        background-color: #fff;
      }
      >div.main-content-wrap-no-bg {
        background-color: transparent;
      }
    }
    ::v-deep .wrap-main-tab {
      background-color: transparent;
      box-shadow: none;
      .el-tabs__content {
        padding: 0px;
      }
      .el-tabs__header {
        background-color: #0F44C6;
        .el-tabs__nav-prev, .el-tabs__nav-next {
          color: #fff;
          line-height: 30px;
        }
      }
      .el-tabs__nav, .el-tabs__item {
        height: 30px;
        line-height: 30px;
      }
      .el-tabs__item {
        background: #446DD0;
        font-size: 12px;
        color: #F5F7FC;
        &:hover {
          color: #F5F7FC;
        }
      }
      .is-active {
        background: #ECEEF3;
        font-size: 12px;
        color: #0F44C6;
        &:hover {
          color: #0F44C6;
        }
      }
    }
  }
}
.portal-fluid-style {
  .sidebar {
    width: 72px;
  }
  .main {
    margin-left: 72px;
  }
}
.sy-layout-fluid-content-fill-page {
  min-height: calc(100vh - 158px);
}
