$img-url: '//img.ythshop.com/';
/*背景色灰色*/
$bg-color: #f1f3f6;
/*默认背景色*/
$default-bg-color: #fff;
/*默认字体大小*/
$default-font-size: 14px;
/*用于table td 字体 placeholder字体大小*/
$default-mini-font-size: 12px;
/*树悬浮颜色*/
$tree-hover-color: #F3F8FF;

$primary-color: #ff8933;
$default-color: #ECEFF2;
$border-color: #ecedee;
$font-color: #333;
$content-color: #666;
$minor-color: #999;
$handle-color: #679dff;
$header-top-bgcolor: #41464B;
$header-top-color: #c7c7c7;
$complate-icon-color: #8cc856;

$tip-font-color: #d6a962;
$tip-highlight-color: #f45d5d;
$tip-border-color: #fbd597;
$tip-bg-color: #fbf3ee;

$step-bg-color: #dedede;

$app-classify-color: #484c59;

$tab-default-color: #454545;






















































































































































































@import '@/common/css/variable.scss';
.sidebar {
    width: 218px;
    background: #fff;
    float: left;
    font-size: 0px;
    // &:after {
    //   content: '';
    //   width: 10px;
    //   background-color: $default-color;
    //   display: block;
    //   position: absolute;
    //   left: 202px;
    //   top: 0;
    //   bottom: 0;
    // }
}
.sidebar-module {
  padding-top: 30px;
  display: inline-block;
  vertical-align: top;
  width: 72px;
  font-size: 12px;
  color: rgba(255,255,255,0.66);
  position: relative;
  z-index: 1;
  .sidebar-module-item {
    width: 100%;
    height: 64px;
    cursor: pointer;
    .item-img {
      width: 16px;
      height: 16px;
      margin: 0 auto;
      padding: 12px 0px 10px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .item-text {
      font-size: 12px;
      color: rgba(255,255,255,0.66);
      text-align: center;
    }
  }
  .sidebar-module-item-active {
    background: #fff;
    color: #222;
    .item-text {
      color: #222;
    }
  }
}
.sidebar-menu {
  display: inline-block;
  vertical-align: top;
  width: 146px;
  font-size: 14px;
  // menu样式覆盖
  ::v-deep .el-menu {
    border: none;
  }
  // ::v-deep .el-menu .el-submenu {
  //   padding: 0px;
  // }
  ::v-deep .el-menu .el-submenu__title {
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    margin: 8px 0px;
    cursor: pointer;
    padding-left: 29px !important;
    padding-right: 10px;
    .el-submenu__icon-arrow {
      left: 12px;
      right: auto;
    }
  }
  ::v-deep .el-menu .el-menu-item {
    height: 32px;
    line-height: 32px;
    padding-left: 29px !important;
    padding-right: 10px;
    cursor: pointer;
    min-width: 86px;
  }
}

