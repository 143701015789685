$img-url: '//img.ythshop.com/';
/*背景色灰色*/
$bg-color: #f1f3f6;
/*默认背景色*/
$default-bg-color: #fff;
/*默认字体大小*/
$default-font-size: 14px;
/*用于table td 字体 placeholder字体大小*/
$default-mini-font-size: 12px;
/*树悬浮颜色*/
$tree-hover-color: #F3F8FF;

$primary-color: #ff8933;
$default-color: #ECEFF2;
$border-color: #ecedee;
$font-color: #333;
$content-color: #666;
$minor-color: #999;
$handle-color: #679dff;
$header-top-bgcolor: #41464B;
$header-top-color: #c7c7c7;
$complate-icon-color: #8cc856;

$tip-font-color: #d6a962;
$tip-highlight-color: #f45d5d;
$tip-border-color: #fbd597;
$tip-bg-color: #fbf3ee;

$step-bg-color: #dedede;

$app-classify-color: #484c59;

$tab-default-color: #454545;























































@import '@/common/css/variable.scss';
#header {
	background: #0F44C6;
	line-height: 56px;
	.header-sunyur {
		float: left;
		width: 200px;
		height: 64px;
		img {
			display: block;
			height: 30px;
			margin: 0 auto;
			padding-top: 15px;
		}
	}
	.header-user {
		float: right;
		padding-right: 20px;
		color: #fff;
		.user-name {
			cursor: pointer;
			i {
				cursor: pointer;
				transform: rotate(90deg);
			}
		}
	}
}
